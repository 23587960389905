<template>
  <header class="header py-2 px-6 flex items-center justify-between">
    <div class="flex items-center">
      <img class="logo mr-8" src="../../assets/logoNew.png" />
      <div class="flex" v-if="getUserType() === 'user'">
        <p
          :class="['menu', { active: $route.path === '/checkList' }]"
          @click="$router.push('/checkList')"
        >
          Check Lists
        </p>
        <p
          :class="['menu', { active: $route.path === '/taskList' }]"
          @click="$router.push('/taskList')"
        >
          Check Tasks
        </p>
        <p
          :class="['menu', { active: $route.path === '/community' }]"
          @click="$router.push('/community')"
        >
          Community
        </p>
        <!-- <p
          :class="['menu', { active: $route.path === '/sharedTasks' }]"
          @click="$router.push('/sharedTasks')"
        >
          Shared Tasks
        </p> -->
      </div>
      <div class="flex" v-if="getUserType() === 'admin'">
        <p
          :class="['menu', { active: $route.path === '/templateList' }]"
          @click="$router.push('/templateList')"
        >
          Templates
        </p>
      </div>
    </div>
    <el-dropdown>
      <div class="flex items-center">
        <div class="mr-3 text-right">
          <p class="text-black">
            {{ getUserName() || "" }}
          </p>
          <p class="text-sm">{{ getUserEmail() || "" }}</p>
        </div>
        <el-avatar :src="avatar || require('../../assets/defaultAvatar.png')" />
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <div class="flex items-center" @click="editUserInfo">
            <i class="el-icon-user mr-2"></i>
            <p>User Profile</p>
          </div>
        </el-dropdown-item>
        <el-dropdown-item>
          <div class="flex items-center" @click="logout">
            <i class="el-icon-switch-button mr-2"></i>
            <p>Logout</p>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog title="User Profile" :visible.sync="userInfoVisible">
      <el-form :model="form" label-position="top">
        <el-form-item label="Name:" :label-width="formLabelWidth">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Avatar:" :label-width="formLabelWidth">
          <el-upload
            class="avatar-uploader flex items-center justify-center"
            action="#"
            :limit="1"
            :before-upload="beforeAttachUpload"
            :show-file-list="false"
          >
            <img v-if="avatar" :src="avatar" class="avatar" />
            <div v-else>
              <i class="el-icon-plus avatar-uploader-icon"></i>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="userInfoVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onUpdateUserInfo">Confirm</el-button>
      </div>
    </el-dialog>
  </header>
</template>

<script>
import { updateUserInfo, getQiniuToken } from "../../api";
import {
  removeUserId,
  removeUserEmail,
  removeUserName,
  getUserName,
  getUserEmail,
  getUserId,
  getUserAvatar,
  setUserAvatar,
  setUserName,
  getUserType,
} from "../../utils/store";
import * as qiniu from "qiniu-js";
export default {
  data() {
    return {
      formLabelWidth: 200,
      userInfoVisible: false,
      form: {
        name: getUserName(),
      },
      avatar: getUserAvatar() || "",
    };
  },
  methods: {
    getUserEmail,
    getUserName,
    getUserAvatar,
    getUserType,
    editUserInfo() {
      this.userInfoVisible = true;
    },
    async onUpdateUserInfo() {
      try {
        const res = await updateUserInfo({
          user_id: getUserId(),
          quality_user_name: this.form.name,
          user_avatar: this.avatar,
        });
        if (res.data.code === 0) {
          this.$message.success("Update successfully.");
          this.userInfoVisible = false;
          setUserName(res.data.data.quality_user_name);
          if (this.$route.path === "/taskList") {
            window.location.reload();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    logout() {
      this.$confirm("Are you sure you want to logout?", "Pay Attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then((res) => {
        removeUserId();
        removeUserEmail();
        removeUserName();
        location.reload();
      });
    },
    async beforeAttachUpload(file) {
      const result = await this.getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: async (res) => {
            const url = "http://mainbucket.reachable-edu.com/" + res.key;
            try {
              const res = await updateUserInfo({
                user_id: getUserId(),
                quality_user_name: this.form.name,
                user_avatar: url,
              });
              if (res.data.code === 0) {
                setUserAvatar(url);
                this.avatar = url;
                setUserName(res.data.data.quality_user_name);
                this.$message.success("Update Avatar Successful.");
                if (this.$route.path === "/taskList") {
                  window.location.reload();
                }
              }
            } catch (error) {
              console.log(error);
            }
          },
        };
        this.observable.subscribe(observer);
      }
    },
    async getQiniuToken() {
      return new Promise((resolve) => {
        getQiniuToken().then((res) => {
          resolve(res);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 1px dashed #27a6f9;
  &-icon {
    color: #27a6f9;
  }
  .avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  .tips {
    color: #27a6f9;
    width: 180px;
  }
}
.header {
  background: #ffede2;
  font-family: "Lato";
  font-size: 24px;
}
.menu {
  font-size: 16px;
  margin-right: 32px;
  line-height: 32px;
  cursor: pointer;
  &.active {
    border-bottom: 2px solid #000;
    font-weight: bold;
  }
}
.logo {
  width: 128px;
  height: 50px;
}
</style>
