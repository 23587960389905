<template>
  <div class="task-list-container py-8 px-12">
    <div class="flex flex-row-reverse mb-4">
      <div class="px-4 py-2 rounded add-btn" @click="addTask">+ New Task</div>
    </div>
    <el-empty v-if="taskList.length === 0" description="No Data"></el-empty>
    <div v-else>
      <div v-for="(item, index) in taskList" :key="index">
        <Task
          :info="item"
          :type="item.user_id === getUserId() ? 'my' : 'share'"
          @delete="del(item)"
          @edit="goTaskDetail(item)"
          @share="onShare(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  getMyCreateCheckTasks,
  getMySharedCheckTasks,
  removeCheckTask,
} from "../../api/index.js";
import Task from "../../components/Task";
import { getUserId } from "../../utils/store";
import unionBy from "lodash/unionBy";
export default {
  data() {
    return {
      taskList: [],
    };
  },
  components: {
    Task,
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getUserId,
    async fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      try {
        const createTaskRes = await getMyCreateCheckTasks({
          user_id: getUserId(),
        });
        const sharedTaskRes = await getMySharedCheckTasks({
          user_id: getUserId(),
        });
        loading.close();
        this.taskList = unionBy(
          [...createTaskRes.data.data, ...sharedTaskRes.data.data],
          "_id.$id"
        );
      } catch (error) {}
    },
    addTask() {
      this.$router.push({
        path: "/taskDetail",
      });
    },
    goTaskDetail(item) {
      this.$router.push({
        path: "/taskDetail",
        query: {
          id: item._id.$id,
        },
      });
    },
    async onShare(item) {
      try {
        this.$copyText(
          `${window.location.host}/#/invitation?id=${item._id.$id}&checkId=${item.checklist_id}`
        ).then(() => this.$message.success("The share link is copied."));
      } catch (error) {}
    },
    del(row) {
      this.$confirm("Are you sure you want to delete it?", "Pay attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          removeCheckTask({
            id: row._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("Delete successfully.");
              this.fetchData();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.task-list-container {
  background-color: #fafafa;
}
.add-btn {
  background: #ffede2;
  width: fit-content;
  cursor: pointer;
  font-family: "Lota";
}
</style>
