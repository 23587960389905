<template>
  <div class="relative">
    <div
      class="custom-input"
      ref="inputContainer"
      contenteditable="true"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="handleInput"
      @keydown="handleKeyDown"
    ></div>
    <span
      v-show="!hasContent && !isFocused"
      class="placeholder italic cursor-pointer absolute top-2 left-0"
      >{{ placeholder || "Enter content..." }}</span
    >
  </div>
</template>

<script>
export default {
  name: "CustomInput",
  props: {
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      content: "",
      isFocused: false,
    };
  },
  mounted() {
    this.content = this.placeholder || "Enter content...";
  },
  computed: {
    hasContent() {
      return this.placeholder
        ? this.content !== this.placeholder
        : this.content !== "Enter content...";
    },
  },
  methods: {
    handleBlur() {
      this.isFocused = false;
      console.log(this.content, !this.hasContent && !this.isFocused);
      // this.$refs.inputContainer.textContent = "";
      // this.$emit("blur", this.content);
    },
    handleFocus() {
      this.isFocused = true;
    },
    handleInput() {
      this.content = this.$refs.inputContainer.textContent;
    },
    handleKeyDown(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.isFocused = false;
        console.log(this.content);
        this.$refs.inputContainer.textContent = "";
        this.$emit("enter", this.content);
        this.content = this.placeholder || "Enter content...";
      }
    },
  },
};
</script>

<style>
.custom-input {
  padding: 10px 0;
  position: relative;
  outline: none;
  z-index: 1;
}

.placeholder {
  color: #aaa;
}
</style>
