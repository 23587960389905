<template>
  <div class="check-list-detail-container flex">
    <div class="flex-1 py-8 px-24">
      <div class="pb-2 mb-2 border-b border-solid border-black">
        <el-input
          class="input"
          v-model="checklistForm.name"
          placeholder="Type a name for your new check list here..."
          @blur="submitForm"
        />
      </div>
      <!-- <div class="mb-6">
        <p class="text-base mb-2">List Introduction:</p>
        <quill-editor
          class="editor"
          v-model="checklistForm.intro"
          :options="editorOption"
        >
        </quill-editor>
      </div> -->
      <div>
        <div
          class="flex items-center cursor-pointer mb-2 justify-between check-item"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div class="flex items-center">
            <div class="circle mr-4"></div>
            <p>{{ item.name }}</p>
          </div>
          <div class="icons">
            <i class="el-icon-edit delete-icon mr-2" @click="edit(item)"></i>
            <i class="el-icon-delete delete-icon" @click="del(item)"></i>
          </div>
        </div>
        <div class="italic mt-4 cursor-pointer" @click="add">
          + Add a new check item into the list
        </div>
        <!-- <div
          class="sync-btn rounded-md py-2 px-4 cursor-pointer mb-8"
          @click="submitForm"
        >
          CREATE
        </div> -->
      </div>
    </div>
    <el-dialog :visible.sync="show" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">Submit</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {
  updateCheckItem,
  updateCheckList,
  removeCheckItem,
} from "../../api/index.js";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      tableData: [],
      form: {},
      show: false,
      checklistForm: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {},
  methods: {
    submitForm() {
      if (!this.checklistForm.name) {
        this.$message.warning("Please input check list name!");
        return;
      }
      updateCheckList({
        id: "",
        name: this.checklistForm.name,
        intro: this.checklistForm.intro || "",
        update_user_id: getUserId(),
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("修改成功");
          this.$router.push({
            path: "/checkListDetail",
            query: {
              id: res.data.data._id.$id,
            },
          });
        }
      });
    },
    add() {
      this.$message.warning("Please add check list first!");
    },
    edit(row) {
      this.form = {
        name: row.name,
        id: row._id.$id,
      };
      this.show = true;
    },
    del(row) {
      this.$confirm("Are you sure you want to delete it?", "Pay attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          removeCheckItem({
            id: row._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("Delete successfully.");
              this.fetchData();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.check-list-detail-container {
  min-height: 100vh;
  font-family: "Lota";
}
.input {
  & /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 18px;
    &::placeholder {
      font-family: "Lota";
      font-size: 18px;
      font-style: italic;
    }
  }
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
}
.icons {
  font-size: 20px;
  color: #ccc;
}
.sync-btn {
  background: #ffede2;
  text-align: center;
  width: 240px;
  margin-top: 12px;
}
.version-content {
  width: 300px;
  background-color: #fafafa;
  height: 100%;
  .info {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 4px;
  }
}
.check-item:hover {
  .delete-icon {
    opacity: 1;
  }
}
.delete-icon {
  opacity: 0;
}
/deep/.editor {
  background-color: #fafafa;
  border-radius: 4px;
  .ql-toolbar.ql-snow {
    border: none;
  }
  .ql-container.ql-snow {
    border: none;
  }
}
</style>
