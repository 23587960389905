<template>
  <div class="check-list-detail-container flex">
    <div class="flex-1 pb-8 px-24">
      <div class="flex items-center my-4 mt-8 cursor-pointer" @click="goList">
        <i class="el-icon-arrow-left mr-2"></i>Back to lists
      </div>
      <div class="pb-1 mb-2 border-b border-solid border-black">
        <el-input
          class="input"
          v-model="checklistForm.name"
          placeholder="Type a name for your new check list here..."
          @blur="submitForm"
        />
      </div>
      <!-- <div class="mb-6">
        <p class="text-base mb-2">List Introduction:</p>
        <quill-editor
          class="editor"
          v-model="checklistForm.intro"
          :options="editorOption"
        >
        </quill-editor>
      </div> -->
      <div>
        <draggable v-model="tableData" @end="handleSort">
          <div
            class="flex items-center cursor-pointer mb-2 px-2 rounded justify-between check-item"
            v-for="(item, index) in tableData"
            :key="index"
            @dblclick="handleChangeItem(item, index)"
          >
            <div class="flex items-center">
              <div
                :class="[
                  'circle mr-4 flex items-center justify-center',
                  { checked: item.status === 'checked' },
                ]"
              >
                <!-- <i
                  :class="[
                    'el-icon-check',
                    { checked: item.status === 'checked' },
                  ]"
                ></i> -->
              </div>
              <p v-if="!item.isEdit">{{ item.name }}</p>
              <el-input
                v-else
                class="custom-input flex-1"
                v-model="item.name"
                @change="(v) => handleKeyDown(v, item, index)"
              />
            </div>
            <div class="icons">
              <i class="el-icon-sort delete-icon mr-2 cursor-move"></i>
              <i class="el-icon-delete delete-icon" @click="del(item)"></i>
            </div>
          </div>
        </draggable>
        <CustomInput
          placeholder="+ Add a new check item into the list"
          @enter="handleAdd"
          @blur="handleAdd"
        />
      </div>
    </div>
    <div class="version-content p-6">
      <div
        class="sync-btn rounded-md py-2 px-4 cursor-pointer mb-8"
        @click="handleSync"
      >
        SYNC
      </div>
      <!-- <div class="flex items-center justify-between info">
        <p>Build Version</p>
        <p>-</p>
      </div> -->
      <div class="flex items-center justify-between info">
        <p>Total items</p>
        <p>{{ tableData.length }}</p>
      </div>
      <div class="flex items-center justify-between info">
        <p>Created on</p>
        <p>
          {{ $dayjs(checklistForm.create_time * 1000).format("MMM D, YYYY") }}
        </p>
      </div>
      <div class="flex items-center justify-between info">
        <p>Last Edit</p>
        <p>
          {{ $dayjs(checklistForm.update_time * 1000).format("MMM D, YYYY") }}
        </p>
      </div>
      <div class="mt-8">
        <div class="flex justify-between items-center">
          <p>Collaborators</p>
          <div
            class="sync-btn rounded-md py-2 px-4 cursor-pointer"
            @click="collaborateShow = true"
          >
            + ADD
          </div>
        </div>
        <div
          v-if="
            checklistForm.collaboraters &&
            checklistForm.collaboraters.length > 0
          "
        >
          <div
            class="flex items-center mt-4 justify-between collaborator"
            v-for="(item, index) in checklistForm.collaboraters"
            :key="index"
          >
            <div class="flex items-center">
              <el-avatar
                :src="
                  item.user_avatar || require('../../assets/defaultAvatar.png')
                "
                size="medium"
                class="mr-3"
              ></el-avatar>
              <div>
                <p class="text-sm">
                  {{ item.quality_user_name || item.user_name }}
                </p>
                <p class="text-xs" style="color: #666">{{ item.user_email }}</p>
              </div>
            </div>
            <el-popconfirm
              title="Are you sure to delete this collaborator?"
              confirm-button-text="Confirm"
              cancel-button-text="Cancel"
              @confirm="removeCollaborator(index)"
            >
              <i slot="reference" class="el-icon-delete cursor-pointer"></i>
            </el-popconfirm>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="show" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">Submit</el-button>
    </el-dialog>
    <el-dialog
      :visible.sync="collaborateShow"
      :close-on-click-modal="false"
      width="600px"
      @close="value = ''"
    >
      <el-form
        :model="collaboratorForm"
        :rules="collaboratorRules"
        ref="collaboratorForm"
        label-width="180px"
      >
        <el-form-item label="Collaborator Email" prop="email">
          <el-input
            v-model="collaboratorForm.email"
            placeholder="Please input collaborator email"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- <div>
        <el-select
          v-model="value"
          filterable
          allow-create
          remote
          reserve-keyword
          placeholder="Please input collaborator email"
          class="w-full"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div> -->
      <div class="w-full flex items-center justify-center mt-8">
        <el-button type="primary" @click="addCollaborator">Submit</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCheckItems,
  updateCheckItem,
  updateCheckList,
  removeCheckItem,
  getCheckList,
  sortCheckItem,
  getCheckResult,
  checkTask,
  searchUserByEmail,
  addCheckListCollaborator,
  removeCheckListCollaborator,
  addCheckListCollaboraterNew,
} from "../../api/index.js";
import CustomInput from "../../components/CustomInput";
import draggable from "vuedraggable";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      tableData: [],
      checkResults: [],
      form: {},
      show: false,
      collaborateShow: false,
      searchEmail: "",
      options: [],
      value: "",
      loading: false,
      checklistForm: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
      collaboratorForm: {
        email: "",
      },
      collaboratorRules: {
        email: [
          {
            type: "email",
            message: "Please input correct email",
            trigger: ["blur", "change"],
          },
          {
            required: true,
            message: "Please input collaborator email",
            trigger: "blur",
          },
        ],
      },
    };
  },
  components: {
    draggable,
    CustomInput,
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    checkResults() {
      this.tableData = this.tableData.map((item) => ({
        ...item,
        status: this.checkResults.find((i) => i.item_id === item._id.$id)
          ? this.checkResults.find((i) => i.item_id === item._id.$id).status
          : "",
      }));
    },
  },
  methods: {
    async remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const res = await searchUserByEmail({
          email: query,
        });
        this.loading = false;
        this.options = res.data.data.map((item) => ({
          label: `${item.quality_user_name || item.user_name}(${
            item.user_email
          })`,
          value: item._id.$id,
          ...item,
        }));
      } else {
        this.options = [];
      }
    },
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getCheckList({
        id: this.$route.query.id,
      }).then((res) => {
        this.checklistForm = res.data.data;
      });
      getCheckItems({
        checklist_id: this.$route.query.id,
      }).then((res) => {
        this.tableData = res.data.data.map((item) => ({
          ...item,
          isEdit: false,
        }));
        loading.close();
      });
      // this.fetchTaskResult();
    },
    fetchTaskResult() {
      getCheckResult({
        task_id: this.$route.query.id,
      }).then((res) => (this.checkResults = res.data.data));
    },
    async onCheck(item) {
      const params = {
        task_id: this.$route.query.id,
        item_id: item._id.$id,
        user_id: getUserId(),
        status: item.status === "checked" ? "" : "checked",
      };
      const res = await checkTask(params);
      if (res.data.code === 0) {
        this.$message.success("Updated successfully.");
        this.fetchTaskResult();
      }
    },
    submitForm() {
      updateCheckList({
        id: this.$route.query.id,
        name: this.checklistForm.name,
        intro: this.checklistForm.intro,
        update_user_id: getUserId(),
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("Update successfully");
          this.fetchData();
        }
      });
    },
    add() {
      this.form = {
        name: "",
        id: "",
      };
      this.show = true;
    },
    submit() {
      updateCheckItem({
        checklist_id: this.$route.query.id,
        id: this.form.id,
        name: this.form.name,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功");
          this.fetchData();
          this.show = false;
        }
      });
    },
    edit(row) {
      this.form = {
        name: row.name,
        id: row._id.$id,
      };
      this.show = true;
    },
    del(row) {
      this.$confirm("Are you sure you want to delete it?", "Pay attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          removeCheckItem({
            id: row._id.$id,
            checklist_id: row.checklist_id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("Delete successfully.");
              this.fetchData();
            }
          });
        })
        .catch(() => {});
    },
    goList() {
      this.$router.push("/checkList");
    },
    handleSync() {
      this.$message.warning("Sync is developing.");
    },
    handleChangeItem(item, index) {
      this.$set(this.tableData[index], "isEdit", true);
    },
    handleKeyDown(event, item, index) {
      updateCheckItem({
        checklist_id: this.$route.query.id,
        id: item._id.$id,
        name: event,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("Update successfully.");
          this.fetchData();
        }
      });
    },
    handleAdd(value) {
      updateCheckItem({
        checklist_id: this.$route.query.id,
        id: "",
        name: value,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功");
          this.fetchData();
        }
      });
    },
    handleSort() {
      sortCheckItem({
        item_list: this.tableData.map((item) => item._id.$id),
        checklist_id: this.$route.query.id,
      });
    },
    addCollaborator() {
      try {
        this.$refs["collaboratorForm"].validate(async (valid) => {
          if (valid) {
            const res = await addCheckListCollaboraterNew({
              email: this.collaboratorForm.email,
              invitor_id: getUserId(),
              checklist_id: this.$route.query.id,
            });
            if (res.data.code === 0) {
              this.$message.success("Add collaborator successfully.");
              this.collaborateShow = false;
              this.$refs["collaboratorForm"].resetFields();
              this.fetchData();
            } else {
              this.$message.error("Add collaborator failed");
            }
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      } catch (error) {}
    },
    async removeCollaborator(index) {
      try {
        const res = await removeCheckListCollaborator({
          checklist_id: this.$route.query.id,
          index,
        });
        if (res.data.code === 0) {
          this.$message.success("Remove collaborator successfully.");
          this.fetchData();
        } else {
          this.$message.error("Remove collaborator failed");
        }
      } catch (error) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.check-list-detail-container {
  min-height: 100vh;
  font-family: "Lota";
}
.collaborator {
  .el-icon-delete {
    opacity: 0;
  }
  &:hover {
    .el-icon-delete {
      opacity: 1;
      color: #666;
    }
  }
}
.custom-input {
  & /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 16px;
    background: #fafafa;
    width: 300px;
    color: black;
  }
}
.input {
  & /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 18px;
    color: #000;
    &::placeholder {
      font-family: "Lota";
      font-size: 18px;
      font-style: italic;
    }
  }
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
  &.checked {
    border-color: #67c23a;
    background-color: #67c23a;
  }
  .el-icon-check {
    opacity: 0;
    &.checked {
      opacity: 1;
      color: white;
    }
  }
  &:hover {
    .el-icon-check {
      opacity: 1;
    }
  }
}
.icons {
  font-size: 20px;
  color: #ccc;
}
.version-content {
  width: 300px;
  background-color: #fafafa;
  height: 100%;
  .sync-btn {
    background: #ffede2;
    text-align: center;
  }
  .info {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 4px;
  }
}
.check-item:hover {
  background-color: #fafafa;
  .delete-icon {
    opacity: 1;
  }
}
.delete-icon {
  opacity: 0;
}
/deep/.editor {
  background-color: #fafafa;
  border-radius: 4px;
  .ql-toolbar.ql-snow {
    border: none;
  }
  .ql-container.ql-snow {
    border: none;
  }
}
</style>
