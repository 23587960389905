<template>
  <div class="homeContainer1">
    <Header />
    <div class="main">
      <div class="right">
        <router-view
          style="height: calc(100vh - 50px); overflow-y: scroll"
        ></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserName, getUserType, removeUserId } from "../utils/store.js";
import Header from "../components/Header/index.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {
      userName: getUserName(),
      userType: getUserType(),
    };
  },
  methods: {
    logout() {
      this.$confirm("是否确认退出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res) => {
        removeUserId();
        location.reload();
      });
    },
  },
};
</script>

<style lang="scss">
.homeContainer1 {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  .home-header {
    border-bottom: 1px solid #dcdfe6;
    box-shadow: 6px 6px 12px 1px rgba(94, 94, 94, 0.11);
    padding: 0 33px;
    color: #333;
    height: 50px;
    flex-shrink: 0;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .logo {
      display: flex;
      align-items: center;
    }
    .logout {
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      cursor: pointer;
      padding: 8px 24px;
      font-size: 16px;
    }
  }
  .main {
    flex: 1;
    display: flex;
    overflow: hidden;
    .leftSide {
      height: 100%;
      width: 200px;
      flex-shrink: 0;
      background: #f9f9ff;
      box-shadow: 2px 0px 12px 1px rgba(96, 91, 255, 0.07);
      .el-menu {
        border: none;
        background: none;
      }
    }
    .right {
      flex: 1;
      height: 100%;
      background: #fff;
      overflow: hidden;
      position: relative;
      .audioContainer {
        flex-shrink: 0;
        height: 86px;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 0 60px 0 30px;
        box-sizing: border-box;
        overflow: hidden;
        position: absolute;
        background: #fff;
        z-index: 100;
        display: flex;
        align-items: center;
        .icon {
          margin-left: 20px;
          flex-shrink: 0;
        }
        .center {
          flex: 1;
          overflow: hidden;
          margin-left: 20px;
          margin-top: 6px;
          .name {
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #304fb8;
          }
          .centerBottom {
            display: flex;
            overflow: hidden;
            align-items: center;
            .time {
              color: #858585;
              flex-shrink: 0;
              letter-spacing: 1px;
              font-size: 14px;
            }
            .process {
              flex: 1;
              margin-left: 20px;
              margin-right: 30px;
            }
            .voice {
              height: 18px;
              margin-right: 20px;
              cursor: pointer;
            }
            .detail {
              height: 18px;
              width: 18px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
