<template>
  <div class="p-4 bg-white mb-3 rounded-md shadow relative task-wrapper">
    <div class="check-btn" @click.stop="goShareTaskDetail(info)">CHECK</div>
    <p
      v-if="info.item_results && info.checklist && info.checklist.item_list"
      class="text-base progress"
    >
      {{ info.item_results.filter((i) => i.status).length }} /
      {{ info.checklist.item_list.length }}
    </p>
    <p class="name">{{ info.name }}</p>
    <p class="intro" v-html="info.intro"></p>
    <div class="flex justify-between time">
      <div class="flex">
        <div class="flex items-center">
          Created by:
          <el-popover placement="bottom-start" trigger="hover">
            <div class="flex items-center">
              <el-avatar
                :src="
                  info.user.user_avatar ||
                  require('../../assets/defaultAvatar.png')
                "
                :size="24"
                class="mr-2"
              ></el-avatar>
              <div>
                <p>{{ info.user.quality_user_name }}</p>
                <p class="text-xs" style="color: #606266">
                  {{ info.user.user_email }}
                </p>
              </div>
            </div>
            <el-avatar
              :src="
                info.user.user_avatar ||
                require('../../assets/defaultAvatar.png')
              "
              :size="24"
              class="ml-2 mr-5 flex items-center cursor-pointer"
              slot="reference"
            ></el-avatar>
          </el-popover>
        </div>
        <div
          class="flex items-center mr-5"
          v-if="info.checkers && info.checkers.length > 0"
        >
          Checkers:
          <el-popover
            placement="bottom-start"
            trigger="hover"
            v-for="(user, index) in unionBy(info.checkers, '_id.$id')"
            :key="index"
          >
            <div class="flex items-center">
              <el-avatar
                :src="
                  user.user_avatar || require('../../assets/defaultAvatar.png')
                "
                :size="24"
                class="mr-2"
              ></el-avatar>
              <div>
                <p>{{ user.quality_user_name || "-" }}</p>
                <p class="text-xs" style="color: #606266">
                  {{ user.user_email }}
                </p>
              </div>
            </div>
            <el-avatar
              :src="
                user.user_avatar || require('../../assets/defaultAvatar.png')
              "
              :size="24"
              class="mx-1 flex items-center cursor-pointer"
              slot="reference"
            ></el-avatar>
          </el-popover>
        </div>
        <div class="flex items-center">
          Updated at:
          <span>{{
            $dayjs(info.update_time * 1000).format("MMM D, YYYY")
          }}</span>
        </div>
      </div>
      <div class="flex items-center">
        <p
          class="mr-3 underline cursor-pointer"
          v-if="type !== 'share'"
          @click="editTask"
        >
          Edit
        </p>
        <el-tooltip
          class="item"
          effect="dark"
          content="You can share this task to others for them to do the checking."
          placement="bottom"
        >
          <p class="underline cursor-pointer mr-2" @click="shareTask">Share</p>
        </el-tooltip>
        <el-dropdown>
          <i
            v-if="type !== 'share'"
            class="el-icon-more delete-icon cursor-pointer rotate-90 transform"
          ></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <p @click.stop="deleteTask">Delete</p>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import unionBy from "lodash/unionBy";
export default {
  props: {
    info: {
      type: Object,
    },
    type: {
      type: String,
    },
  },
  methods: {
    unionBy,
    deleteTask() {
      this.$emit("delete");
    },
    editTask() {
      this.$emit("edit");
    },
    shareTask() {
      this.$emit("share");
    },
    goShareTaskDetail(item) {
      this.$router.push({
        path: "/sharedTaskDetail",
        query: {
          id: item.checklist_id,
          taskId: item._id.$id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.name {
  font-family: "Lato";
  font-size: 18px;
  margin-bottom: 8px;
}
.intro {
  font-family: "Lato";
  font-size: 14px;
  color: #777;
  margin-bottom: 8px;
}
.time {
  font-family: "Lato";
  font-size: 14px;
  color: #777;
}
.task-wrapper {
  .delete-icon {
    color: #777;
    font-size: 16px;
    margin-right: -10px;
  }
}
.check-btn {
  border-radius: 4px;
  background: #ec5720;
  color: white;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 6px 8px;
  cursor: pointer;
}
.progress {
  position: absolute;
  top: 18px;
  right: 90px;
  color: #777;
}
</style>
