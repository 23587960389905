import Vue from "vue";
import Router from "vue-router";
import { getUserId } from "../utils/store.js";
import Login from "../components/Login/index.vue";
import layout from "../views/layout.vue";
import checkTask from "../views/checkTask/index.vue";
import checkTaskDetail from "../views/checkTask/checkTaskDetail.vue";
import CheckList from "../views/CheckList";
import CheckListDetail from "../views/CheckListDetail";
import AddCheckList from "../views/AddCheckList";
import TaskList from "../views/TaskList";
import TaskDetail from "../views/TaskDetail";
import OPTVerify from "../views/OTPVerify";
import Invitation from "../views/Invitation";
import SharedTaskList from "../views/SharedTaskList";
import SharedTaskDetail from "../views/SharedTaskDetail";
import Community from "../views/Community";
import TemplateList from "../views/TemplateList";
import { getUserType } from "../utils/store.js";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/checkList",
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: OPTVerify,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/optVerify",
      name: "OPTVerify",
      component: OPTVerify,
    },
    {
      path: "/invitation",
      name: "invitation",
      component: Invitation,
    },
    {
      path: "/layout",
      name: "layout",
      component: layout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/checkList",
          name: "checklist",
          component: CheckList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/checkListDetail",
          name: "checkListDetail",
          component: CheckListDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/addCheckList",
          name: "addCheckList",
          component: AddCheckList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/checktask",
          name: "checktask",
          component: checkTask,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/checktask-detail",
          name: "checkTaskDetail",
          component: checkTaskDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/taskList",
          name: "taskList",
          component: TaskList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/taskDetail",
          name: "taskDetail",
          component: TaskDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/sharedTasks",
          name: "sharedTasks",
          component: SharedTaskList,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/sharedTaskDetail",
          name: "sharedTaskDetail",
          component: SharedTaskDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/community",
          name: "community",
          component: Community,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/templateList",
          name: "templateList",
          component: TemplateList,
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    if (to.meta.requiresAuth && !getUserId()) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  }
});
export default router;
