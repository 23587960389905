<template>
  <div class="app-container">
    <div class="mb-4">
      <el-button @click="onAdd" type="primary">Add Template</el-button>
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
    >
      <!-- <el-table-column type="expand">
        <template slot-scope="props">
          <div>
            <div v-for="(item, index) in props.row.item_info_list" :key="index">
              {{ item.name }}
            </div>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column align="center" label="Name" width="240">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="Introduction">
        <template slot-scope="scope">
          {{ scope.row.intro }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="Update Time" width="200">
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{
            $dayjs(scope.row.update_time * 1000).format("YYYY-MM-DD HH:mm")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="Action" width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onUpdate(scope.row)"
            >Edit</el-button
          >
          <el-button
            type="text"
            size="small"
            @click="onDelete(scope.row)"
            class="text-red-500 hover:text-red-400"
            >Delete</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="Template Information"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item
          label="Name:"
          prop="name"
          :rules="{
            required: true,
            message: 'Please input name',
            trigger: 'blur',
          }"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="Introduction:"
          prop="intro"
          :rules="{
            required: true,
            message: 'Please input introduction',
            trigger: 'blur',
          }"
        >
          <el-input v-model="form.intro" type="textarea"></el-input>
        </el-form-item>
        <!-- <div v-if="form.id">
          <el-form-item label="Check List:">
            <el-button @click="onAddCheckItem">Add Check Item</el-button>
          </el-form-item>
          <el-form-item
            v-for="(item, index) in form.item_list"
            label=" "
            :key="item.key"
            :prop="'item_list.' + index + '.name'"
            :rules="{
              required: true,
              message: 'Please input check item name',
              trigger: 'blur',
            }"
          >
            <div class="flex">
              <el-input v-model="item.name" class="mr-2"></el-input
              ><el-button @click.prevent="removeCheckItem(item)"
                >Delete</el-button
              >
            </div>
          </el-form-item>
        </div> -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="submitLoading"
            >Confirm</el-button
          >
          <el-button @click="handleClose">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCheckListTemplates,
  removeCheckTemplate,
  updateCheckItem,
  updateCheckListTemplate,
} from "../../api";

export default {
  filters: {},
  data() {
    return {
      list: null,
      listLoading: true,
      dialogVisible: false,
      form: {
        id: "",
        name: "",
        intro: "",
        item_list: [],
      },
      submitLoading: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.listLoading = true;
      getCheckListTemplates().then((response) => {
        this.list = response.data.data;
        this.listLoading = false;
      });
    },
    handleClose() {
      this.$refs["form"].resetFields();
      this.form = {
        id: "",
        name: "",
        intro: "",
        item_list: [],
      };
      this.dialogVisible = false;
    },
    onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.submitLoading = true;
            let itemList = [];
            // if (this.form.item_list && this.form.item_list.length > 0) {
            //   const existed = this.form.item_list.filter((i) => i._id);
            //   const newItems = this.form.item_list.filter((i) => !i._id);
            //   Promise.all(
            //     newItems.map((i) =>
            //       updateCheckItem({
            //         checklist_id: this.form.id,
            //         id: i._id ? i._id.$id : "",
            //         name: i.name,
            //       })
            //     )
            //   ).then((res) => console.log("====>", res));
            // }
            const res = await updateCheckListTemplate({
              ...this.form,
              item_list: [],
            });
            if (res) {
              this.$refs["form"].resetFields();
              this.dialogVisible = false;
              this.$message.success("Update template successful.");
              this.fetchData();
            }
          } catch (error) {
          } finally {
            this.submitLoading = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onAdd() {
      this.dialogVisible = true;
    },
    onUpdate(item) {
      this.dialogVisible = true;
      this.form = {
        ...item,
        id: item._id.$id,
        item_list: item.item_list_info || [],
      };
    },
    onDelete(item) {
      this.$confirm("Are you sure you want to delete it?", "Pay attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          removeCheckTemplate({
            id: item._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("Delete successfully.");
              this.fetchData();
            }
          });
        })
        .catch(() => {});
    },
    onAddCheckItem() {
      this.form.item_list.push({
        name: "",
      });
    },
    removeCheckItem(item) {
      const index = this.form.item_list.indexOf(item);
      if (index !== -1) {
        this.form.item_list.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  padding: 48px;
}
</style>
