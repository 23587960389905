<template>
  <div class="task-detail-container flex justify-center">
    <div>
      <div class="flex items-center my-4 mt-8 cursor-pointer" @click="goList">
        <i class="el-icon-arrow-left mr-2"></i>Back
      </div>
      <div class="task-detail p-6 rounded-lg">
        <div class="pb-2 mb-2 border-b border-solid border-black">
          <el-input
            class="input"
            v-model="name"
            placeholder="Type a name for your new task here..."
          />
        </div>
        <div class="my-6">
          <el-select
            class="w-full check-list-select"
            filterable
            placeholder="Please select the check list for this task"
            v-model="checkId"
          >
            <el-option
              v-for="item in checkList"
              :key="item._id.$id"
              :label="item.name"
              :value="item._id.$id"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <p class="title">Further elaboration for this task:</p>
          <p class="desc">Supplementary comments, links of documents, etc.</p>
          <quill-editor class="editor" v-model="intro" :options="editorOption">
          </quill-editor>
        </div>
        <div class="flex justify-center">
          <div
            class="text-lg px-6 py-2 mt-8 rounded cursor-pointer create-btn"
            @click="submit"
          >
            {{ editType === "add" ? "CREATE" : "UPDATE" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCheckTask,
  updateCheckTask,
  getCheckLists,
  getMyCreateCheckLists,
} from "../../api/index.js";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      intro: "",
      name: "",
      checkList: [],
      checkId: "",
      editorOption: {
        modules: {
          toolbar: [], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  computed: {
    editType() {
      return this.$route.query.id ? "edit" : "add";
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.fetchData();
    }
    getMyCreateCheckLists({
      update_user_id: getUserId(),
    }).then((res) => {
      if (res.data.code === 0) {
        this.checkList = res.data.data;
      }
    });
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getCheckTask({
        id: this.$route.query.id,
      }).then((res) => {
        this.intro = res.data.data.intro;
        this.name = res.data.data.name;
        this.checkId = res.data.data.checklist_id;
        loading.close();
      });
    },
    goList() {
      this.$router.push({
        path: "/taskList",
      });
    },
    submit() {
      if (!this.checkId) {
        this.$message.success("Please select checkList.");
        return;
      }
      updateCheckTask({
        id: this.$route.query.id || "",
        name: this.name,
        intro: this.intro,
        checklist_id: this.checkId,
        user_id: getUserId(),
        time_limit: "",
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success(
            `${this.editType === "edit" ? "Updated" : "Add"} successfully!`
          );
          if (this.editType === "edit") {
            this.fetchData();
          } else {
            this.$router.push({
              path: "/taskList",
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.task-detail-container {
  background: #fafafa;
  font-family: "Lota";
}
.task-detail {
  width: 850px;
  margin-top: 32px;
  background: white;
  height: fit-content;
  .title {
    font-size: 16px;
  }
  .desc {
    font-size: 12px;
    color: #777;
    margin-bottom: 12px;
  }
  .input {
    & /deep/ .el-input__inner {
      border: none;
      padding: 0;
      font-family: "Lota";
      font-size: 18px;
      color: black;
      &::placeholder {
        font-family: "Lota";
        font-size: 18px;
        font-style: italic;
      }
    }
  }
  .check-list-select {
    & /deep/ .el-input__inner {
      border: none;
      font-family: "Lota";
      font-size: 16px;
      background-color: #f1f1f1;
      &::placeholder {
        font-family: "Lota";
        font-size: 16px;
        font-style: italic;
      }
    }
  }
  .create-btn {
    background: #ffede2;
  }
}
/deep/.editor {
  background-color: #fafafa;
  border-radius: 4px;
  .ql-toolbar.ql-snow {
    border: none;
    display: none;
  }
  .ql-container.ql-snow {
    border: none;
  }
}
</style>
