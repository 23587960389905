<template>
  <div class="task-list-container py-8 px-12">
    <el-empty v-if="taskList.length === 0" description="No Data"></el-empty>
    <div v-else>
      <div v-for="(item, index) in taskList" :key="index">
        <Task :info="item" type="share" />
      </div>
    </div>
  </div>
</template>

<script>
import { getMySharedCheckTasks } from "../../api/index.js";
import Task from "../../components/Task";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      taskList: [],
    };
  },
  components: {
    Task,
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getMySharedCheckTasks({
        user_id: getUserId(),
      }).then((res) => {
        this.taskList = res.data.data;
        loading.close();
      });
    },
    addTask() {
      this.$router.push({
        path: "/taskDetail",
      });
    },
    goTaskDetail(item) {
      this.$router.push({
        path: "/taskDetail",
        query: {
          id: item._id.$id,
        },
      });
    },
    // async onShare(item) {
    //   try {
    //     this.$copyText(
    //       `${window.location.host}/#/invitation?id=${item._id.$id}`
    //     ).then(() => this.$message.success("The share link is copied."));
    //   } catch (error) {}
    // },
    // del(row) {
    //   this.$confirm("Are you sure you want to delete it?", "Prompts", {
    //     confirmButtonText: "Confirm",
    //     cancelButtonText: "Cancel",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       removeCheckTask({
    //         id: row._id.$id,
    //       }).then((res) => {
    //         if (res.data.code === 0) {
    //           this.$message.success("Delete successfully.");
    //           this.fetchData();
    //         }
    //       });
    //     })
    //     .catch(() => {});
    // },
  },
};
</script>

<style lang="scss" scoped>
.task-list-container {
  background-color: #fafafa;
}
.add-btn {
  background: #ffede2;
  width: fit-content;
  cursor: pointer;
  font-family: "Lota";
}
</style>
