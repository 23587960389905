<template>
  <div class="createrBook">
    <el-button type="primary" @click="add">新增检查任务</el-button>
    <el-table :data="tableData" style="margin-top: 10px">
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="简介">
        <template slot-scope="scope">
          <div v-html="scope.row.intro"></div>
        </template>
      </el-table-column>
      <el-table-column label="检查清单" prop="checklist.name"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary" size="medium" @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-button type="danger" size="medium" @click="del(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="show" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="简介">
          <quill-editor
            class="editor"
            style="margin-top: 40px; margin-bottom: 20px"
            v-model="form.intro"
            :options="editorOption"
          >
          </quill-editor>
        </el-form-item>
        <el-form-item label="检查清单">
          <el-select v-model="form.checklist_id">
            <el-option
              v-for="item in checklists"
              :key="item._id.$id"
              :label="item.name"
              :value="item._id.$id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">提交</el-button>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCheckTasks,
  updateCheckTask,
  removeCheckTask,
  getCheckLists,
  getMyCreateCheckTasks,
  getMySharedCheckTasks,
} from "../../api/index.js";
import { getUserId } from "../../utils/store.js";
export default {
  data() {
    return {
      tableData: [],
      form: {},
      show: false,
      checklists: [],
      editorOption: {
        modules: {
          toolbar: [], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {
    this.fetchData();
    getCheckLists().then((res) => {
      if (res.data.code === 0) {
        this.checklists = res.data.data;
      }
    });
  },
  methods: {
    fetchData() {
      getMySharedCheckTasks({
        user_id: getUserId(),
      }).then((res) => {
        this.tableData = res.data.data;
      });
    },
    add() {
      this.form = {
        name: "",
        intro: "",
        id: "",
        checklist_id: "",
      };
      this.show = true;
    },
    submit() {
      updateCheckTask({
        id: this.form.id,
        name: this.form.name,
        intro: this.form.intro,
        checklist_id: this.form.checklist_id,
        user_id: getUserId(),
        time_limit: "",
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功");
          this.fetchData();
          this.show = false;
        }
      });
    },
    edit(row) {
      this.$router.push({
        path: "/checktask-detail",
        query: {
          id: row._id.$id,
        },
      });
    },
    del(row) {
      this.$confirm("Are you sure you want to delete it?", "Pay attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          removeCheckTask({
            id: row._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("Delete successfully.");
              this.fetchData();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.createrBook {
  width: 100%;
  padding: 16px;
}
</style>
<style>
.ql-container {
  height: 200px !important;
}
</style>
