import request from "../utils/request";

export function loginByEmailNew(param) {
  return request.post("./index.php", {
    function: "loginByEmailNew",
    ...param,
  });
}
export function updateCheckList(param) {
  return request.post("./index.php", {
    function: "updateCheckList",
    ...param,
  });
}
export function getCheckList(param) {
  return request.post("./index.php", {
    function: "getCheckList",
    ...param,
  });
}
export function getCheckLists(param) {
  return request.post("./index.php", {
    function: "getCheckLists",
    ...param,
  });
}
export function removeCheckList(param) {
  return request.post("./index.php", {
    function: "removeCheckList",
    ...param,
  });
}
export function updateCheckTask(param) {
  return request.post("./index.php", {
    function: "updateCheckTask",
    ...param,
  });
}
export function getCheckTasks(param) {
  return request.post("./index.php", {
    function: "getCheckTasks",
    ...param,
  });
}
export function removeCheckTask(param) {
  return request.post("./index.php", {
    function: "removeCheckTask",
    ...param,
  });
}
export function updateCheckItem(param) {
  return request.post("./index.php", {
    function: "updateCheckItem",
    ...param,
  });
}
export function getCheckItems(param) {
  return request.post("./index.php", {
    function: "getCheckItems",
    ...param,
  });
}
export function removeCheckItem(param) {
  return request.post("./index.php", {
    function: "removeCheckItem",
    ...param,
  });
}
export function getCheckTask(param) {
  return request.post("./index.php", {
    function: "getCheckTask",
    ...param,
  });
}
export function sortCheckItem(param) {
  return request.post("./index.php", {
    function: "sortCheckItem",
    ...param,
  });
}

export function createLoginPinCode(params) {
  return request.post("./index.php", {
    function: "createLoginPinCode",
    ...params,
  });
}

export function verifyLogin(params) {
  return request.post("./index.php", {
    function: "verifyLogin",
    ...params,
  });
}

export function checkTask(params) {
  return request.post("./index.php", {
    function: "checkTask",
    ...params,
  });
}

export function getCheckResult(params) {
  return request.post("./index.php", {
    function: "getCheckResult",
    ...params,
  });
}

export function shareTask(params) {
  return request.post("./index.php", {
    function: "shareTask",
    ...params,
  });
}

export function getInvitation(params) {
  return request.post("./index.php", {
    function: "getInvitation",
    ...params,
  });
}

export function getMyCreateCheckLists(params) {
  return request.post("./index.php", {
    function: "getMyCreateCheckLists",
    ...params,
  });
}

export function getMyCreateCheckTasks(params) {
  return request.post("./index.php", {
    function: "getMyCreateCheckTasks",
    ...params,
  });
}

export function getMySharedCheckTasks(params) {
  return request.post("./index.php", {
    function: "getMySharedCheckTasks",
    ...params,
  });
}

export function updateUserInfo(params) {
  return request.post("./index.php", {
    function: "updateUserInfo",
    ...params,
  });
}

export function getQiniuToken(param) {
  return request.post("./index.php", {
    function: "getQiniuToken",
    ...param,
  });
}

export function addCheckListCollaborator(param) {
  return request.post("./index.php", {
    function: "addCheckListCollaborater",
    ...param,
  });
}

export function removeCheckListCollaborator(param) {
  return request.post("./index.php", {
    function: "removeCheckListCollaborater",
    ...param,
  });
}

export function getMyCollaborateCheckLists(param) {
  return request.post("./index.php", {
    function: "getMyCollaborateCheckLists",
    ...param,
  });
}

export function searchUserByEmail(param) {
  return request.post("./index.php", {
    function: "searchUserByEmail",
    ...param,
  });
}

export function addCheckListCollaboraterNew(param) {
  return request.post("./index.php", {
    function: "addCheckListCollaboraterNew",
    ...param,
  });
}

export function getCheckListTemplates(param) {
  return request.post("./index.php", {
    function: "getCheckListTemplates",
    ...param,
  });
}

export function removeCheckTemplate(param) {
  return request.post("./index.php", {
    function: "removeCheckTemplate",
    ...param,
  });
}

export function updateCheckListTemplate(param) {
  return request.post("./index.php", {
    function: "updateCheckListTemplate",
    ...param,
  });
}
