<template>
  <div class="check-list-container py-8 px-12">
    <div class="flex flex-wrap">
      <div
        v-for="(item, index) in checkList"
        class="check-item rounded-md shadow-lg p-3 relative mr-8 mb-6 overflow-hidden"
        :key="index"
      >
        <p class="pb-2 border-b border-solid border-black">{{ item.name }}</p>
        <p class="my-1 text-right text-xs">
          Last edit: {{ $dayjs(item.update_time * 1000).format("MMM D, YYYY") }}
        </p>
        <div
          v-for="(i, index) in item.item_info_list"
          :key="index"
          class="flex items-center"
        >
          <div class="circle mr-2"></div>
          <p
            class="flex-1 text-sm overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            {{ i.name }}
          </p>
        </div>
        <!-- <i class="el-icon-delete delete-icon" @click.stop="del(item)"></i> -->
      </div>
    </div>
  </div>
</template>

<script>
import { removeCheckList, getCheckListTemplates } from "../../api/index.js";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      checkList: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      Promise.all([getCheckListTemplates()])
        .then(([templateList]) => {
          this.checkList = templateList.data.data;
        })
        .finally(() => loading.close());
    },
    goDetail(item) {
      this.$router.push({
        path: "/checkListDetail",
        query: {
          id: item._id.$id,
        },
      });
    },
    goAdd() {
      this.$router.push({
        path: "/addCheckList",
      });
    },
    del(row) {
      this.$confirm("Are you sure you want to delete it?", "Pay attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          removeCheckList({
            id: row._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("Delete successfully.");
              this.fetchData();
            }
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.check-list-container {
  background-color: #fafafa;
  font-family: "Lato";
  .check-item {
    width: 200px;
    height: 280px;
    background: white;
    cursor: pointer;
    &.add-check-list {
      border: 1px dashed #333;
    }
    .circle {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: #d9d9d9;
      border: 1px solid black;
    }
    .delete-icon {
      position: absolute;
      right: 12px;
      bottom: 12px;
      opacity: 0;
      color: #ccc;
    }
    &:hover {
      .delete-icon {
        opacity: 1;
      }

      transform: scale(1.02);
      transition: all 0.2s;
    }
  }
}
</style>
