var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"mb-4"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onAdd}},[_vm._v("Add Template")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"data":_vm.list,"element-loading-text":"Loading","border":"","fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"align":"center","label":"Name","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Introduction"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.intro)+" ")]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"Update Time","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('i',{staticClass:"el-icon-time"}),_c('span',[_vm._v(_vm._s(_vm.$dayjs(scope.row.update_time * 1000).format("YYYY-MM-DD HH:mm")))])]}}])}),_c('el-table-column',{attrs:{"fixed":"right","label":"Action","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.onUpdate(scope.row)}}},[_vm._v("Edit")]),_c('el-button',{staticClass:"text-red-500 hover:text-red-400",attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.onDelete(scope.row)}}},[_vm._v("Delete")])]}}])})],1),_c('el-dialog',{attrs:{"title":"Template Information","visible":_vm.dialogVisible,"width":"50%","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"120px"}},[_c('el-form-item',{attrs:{"label":"Name:","prop":"name","rules":{
          required: true,
          message: 'Please input name',
          trigger: 'blur',
        }}},[_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"Introduction:","prop":"intro","rules":{
          required: true,
          message: 'Please input introduction',
          trigger: 'blur',
        }}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.form.intro),callback:function ($$v) {_vm.$set(_vm.form, "intro", $$v)},expression:"form.intro"}})],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","loading":_vm.submitLoading},on:{"click":_vm.onSubmit}},[_vm._v("Confirm")]),_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }