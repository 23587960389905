<template>
  <div class="check-list-detail-container flex">
    <div class="flex-1 pb-8 px-24">
      <div class="flex items-center my-4 mt-8 cursor-pointer" @click="goList">
        <i class="el-icon-arrow-left mr-2"></i>Back to lists
      </div>
      <div class="pb-2 mb-3 border-b border-solid border-black">
        <div class="input">{{ checklistForm.name }}</div>
      </div>
      <!-- <div class="mb-6">
        <p class="text-base mb-2">List Introduction:</p>
        <quill-editor
          class="editor"
          v-model="checklistForm.intro"
          :options="editorOption"
        >
        </quill-editor>
      </div> -->
      <div>
        <div
          class="flex items-center cursor-pointer mb-2 px-2 rounded justify-between check-item"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div class="flex items-center">
            <div
              :class="['circle mr-4 flex items-center justify-center']"
              @click.stop="onCheckItem(item)"
            >
              <i
                v-if="item.status === 'checked'"
                :class="['el-icon-success']"
              ></i>
              <i
                v-if="item.status === 'question'"
                :class="['el-icon-question']"
              ></i>
              <i v-if="item.status === 'cancel'" :class="['el-icon-error']"></i>
            </div>
            <p v-if="!item.isEdit">{{ item.name }}</p>
            <el-input
              v-else
              class="custom-input flex-1"
              v-model="item.name"
              @change="(v) => handleKeyDown(v, item, index)"
            />
          </div>
        </div>

        <!-- <CustomInput
          placeholder="+ Add a new check item into the list"
          @enter="handleAdd"
          @blur="handleAdd"
        /> -->
      </div>
    </div>
    <div class="version-content p-6">
      <div class="flex items-center justify-between info">
        <p>Total items</p>
        <p>{{ tableData.length }}</p>
      </div>
      <div class="flex items-center justify-between info">
        <p>Created on</p>
        <p>
          {{ $dayjs(checklistForm.create_time * 1000).format("MMM D, YYYY") }}
        </p>
      </div>
      <div class="flex items-center justify-between info">
        <p>Last Edit</p>
        <p>
          {{ $dayjs(checklistForm.update_time * 1000).format("MMM D, YYYY") }}
        </p>
      </div>
    </div>
    <el-dialog :visible.sync="show" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" @click="submit">Submit</el-button>
    </el-dialog>
    <el-dialog title="Task Status" :visible.sync="dialogVisible">
      <el-select
        class="w-full"
        v-model="taskStatus"
        placeholder="Please select task status"
      >
        <el-option label="Checked" value="checked">
          <div class="flex items-center">
            <i class="el-icon-success mr-3" />
            <p>Checked</p>
          </div>
        </el-option>
        <el-option label="Cancel" value="cancel">
          <div class="flex items-center">
            <i class="el-icon-error mr-3" />
            <p>Cancel</p>
          </div>
        </el-option>
        <el-option label="Question" value="question">
          <div class="flex items-center">
            <i class="el-icon-question mr-3" />
            <p>Question</p>
          </div>
        </el-option>
        <el-option label="Unchecked" value="">
          <div class="flex items-center">
            <i class="el-icon-remove mr-3" />
            <p>Unchecked</p>
          </div>
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="onCheck">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCheckItems,
  updateCheckItem,
  updateCheckList,
  removeCheckItem,
  getCheckList,
  sortCheckItem,
  getCheckResult,
  checkTask,
} from "../../api/index.js";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      tableData: [],
      checkResults: [],
      form: {},
      show: false,
      checklistForm: {},
      statusList: ["checked", "cancel", "question", ""],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
      dialogVisible: false,
      taskStatus: "",
      currentItem: "",
    };
  },
  components: {},
  mounted() {
    this.fetchData();
  },
  watch: {
    checkResults() {
      this.tableData = this.tableData.map((item) => ({
        ...item,
        status: this.checkResults.find((i) => i.item_id === item._id.$id)
          ? this.checkResults.find((i) => i.item_id === item._id.$id).status
          : "",
      }));
    },
  },
  methods: {
    fetchData() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getCheckList({
        id: this.$route.query.id,
      }).then((res) => {
        this.checklistForm = res.data.data;
      });
      getCheckItems({
        checklist_id: this.$route.query.id,
      }).then((res) => {
        this.tableData = res.data.data.map((item) => ({
          ...item,
          isEdit: false,
        }));
        this.fetchTaskResult();
        loading.close();
      });
    },
    fetchTaskResult() {
      getCheckResult({
        task_id: this.$route.query.taskId,
      }).then((res) => (this.checkResults = res.data.data));
    },
    async onCheckItem(item) {
      const index = this.statusList.findIndex((i) => i === item.status);
      const params = {
        task_id: this.$route.query.taskId,
        item_id: item._id.$id,
        user_id: getUserId(),
        status:
          index === this.statusList.length - 1
            ? this.statusList[0]
            : this.statusList[index + 1],
      };
      const res = await checkTask(params);
      if (res.data.code === 0) {
        this.fetchTaskResult();
      } else {
        this.$message.error("Update failed.");
      }
    },
    async onCheck() {
      // if (!this.taskStatus) {
      //   this.$message.warning("Please select task status");
      //   return;
      // }
      const params = {
        task_id: this.$route.query.id,
        item_id: this.currentItem,
        user_id: getUserId(),
        status: this.taskStatus,
      };
      const res = await checkTask(params);
      if (res.data.code === 0) {
        this.$message.success("Updated successfully.");
        this.fetchTaskResult();
        this.dialogVisible = false;
      }
    },
    submitForm() {
      updateCheckList({
        id: this.$route.query.id,
        name: this.checklistForm.name,
        intro: this.checklistForm.intro,
        update_user_id: getUserId(),
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("Update successfully");
          this.fetchData();
        }
      });
    },
    add() {
      this.form = {
        name: "",
        id: "",
      };
      this.show = true;
    },
    submit() {
      updateCheckItem({
        checklist_id: this.$route.query.id,
        id: this.form.id,
        name: this.form.name,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功");
          this.fetchData();
          this.show = false;
        }
      });
    },
    edit(row) {
      this.form = {
        name: row.name,
        id: row._id.$id,
      };
      this.show = true;
    },
    del(row) {
      this.$confirm("Are you sure you want to delete it?", "Pay attention", {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          removeCheckItem({
            id: row._id.$id,
            checklist_id: row.checklist_id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.$message.success("Delete successfully.");
              this.fetchData();
            }
          });
        })
        .catch(() => {});
    },
    goList() {
      this.$router.push("/taskList");
    },
    handleSync() {
      this.$message.warning("Sync is developing.");
    },
    handleChangeItem(item, index) {
      this.$set(this.tableData[index], "isEdit", true);
    },
    handleKeyDown(event, item, index) {
      updateCheckItem({
        checklist_id: this.$route.query.id,
        id: item._id.$id,
        name: event,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("Update successfully.");
          this.fetchData();
        }
      });
    },
    handleAdd(value) {
      updateCheckItem({
        checklist_id: this.$route.query.id,
        id: "",
        name: value,
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("操作成功");
          this.fetchData();
        }
      });
    },
    handleSort() {
      sortCheckItem({
        item_list: this.tableData.map((item) => item._id.$id),
        checklist_id: this.$route.query.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.check-list-detail-container {
  min-height: 100vh;
  font-family: "Lota";
}
.custom-input {
  & /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 16px;
    background: #fafafa;
    width: 300px;
  }
}
.input {
  font-family: "Lota";
  font-size: 18px;
  & /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-family: "Lota";
    font-size: 18px;
    &::placeholder {
      font-family: "Lota";
      font-size: 18px;
      font-style: italic;
    }
  }
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #000;
}
.icons {
  font-size: 20px;
  color: #ccc;
}
.version-content {
  width: 300px;
  background-color: #fafafa;
  height: 100%;
  .sync-btn {
    background: #ffede2;
    text-align: center;
  }
  .info {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 4px;
  }
}
.check-item:hover {
  background-color: #fafafa;
  .delete-icon {
    opacity: 1;
  }
}
.delete-icon {
  opacity: 0;
}
/deep/.editor {
  background-color: #fafafa;
  border-radius: 4px;
  .ql-toolbar.ql-snow {
    border: none;
  }
  .ql-container.ql-snow {
    border: none;
  }
}
.el-icon-success {
  font-size: 24px;
  color: #67c23a !important;
}
.el-icon-error {
  font-size: 24px;
  color: #f56c6c !important;
}
.el-icon-question {
  font-size: 24px;
  color: #e6a23c !important;
}
.el-icon-remove {
  font-size: 24px;
}
</style>
