<template>
  <div class="verify-wrapper">
    <div class="verify-box flex flex-col items-center">
      <img class="logo" src="../../assets/logoNew.png" />
      <div class="verify-container mt-4 flex flex-col items-center">
        <div class="flex items-center w-full mb-4">
          <el-avatar
            :src="inviteInfo.creater_info.user_avatar || ''"
            class="mr-3"
          />
          <div>
            <p>{{ inviteInfo.quality_user_name }}</p>
            <p class="text-xs" style="color: #666">
              {{ inviteInfo.user_info.user_email }}
            </p>
          </div>
        </div>
        <p class="mb-2 w-full">has invited you to a check task on Qualiti:</p>
        <p class="mb-6 w-full text-xl">{{ inviteInfo.task_name }}</p>
        <div
          class="login-btn py-2 px-4 flex items-center justify-center"
          @click="handleAccept"
        >
          ACCEPT
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInvitation, shareTask, getCheckTasks } from "../../api/index.js";
import { getUserId } from "../../utils/store";
export default {
  data() {
    return {
      inviteInfo: {},
    };
  },
  mounted() {
    this.fetchInvitation();
  },
  methods: {
    fetchInvitation() {
      if (!getUserId()) {
        this.$router.push({
          path: "/login",
        });
        return;
      }
      getCheckTasks().then((res) => {
        const currentTask = res.data.data.find(
          (item) => item._id.$id === this.$route.query.id
        );
        shareTask({
          task_id: this.$route.query.id,
          user_id: getUserId(),
          creater_id: currentTask.user_id,
        }).then((res) =>
          getInvitation({
            id: res.data.data._id.$id,
          }).then((res) => {
            if (res.data.code === 0) {
              this.inviteInfo = res.data.data;
            }
          })
        );
      });
    },
    handleAccept() {
      this.$router.push({
        path: "/sharedTaskDetail",
        query: {
          id: this.$route.query.checkId,
          taskId: this.inviteInfo.task_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.verify-wrapper {
  background-color: #fafafa;
  width: 100vw;
  height: 100vh;
  .input {
    & /deep/ .el-input__inner {
      border: none;
      font-family: "Lota";
      background-color: #fafafa;
      &::placeholder {
        font-family: "Lota";
      }
    }
  }
  .verify-box {
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
  }
  .logo {
    width: 160px;
    height: 64px;
  }
  .verify-container {
    width: 480px;
    background-color: white;
    padding: 64px 48px;
    border-radius: 12px;
  }
  .login-btn {
    font-family: "Lato";
    font-size: 14px;
    border-radius: 4px;
    background: #ec5720;
    color: white;
    width: fit-content;
    cursor: pointer;
  }
  .tips {
    color: #666;
    font-size: 12px;
  }
}
</style>
