<template>
  <div class="createrBook">
    <el-form :model="checktaskForm" style="width: 90%">
      <el-form-item label="检查任务名称">
        <el-input v-model="checktaskForm.name"></el-input>
      </el-form-item>
      <el-form-item label="简介">
        <quill-editor
          class="editor"
          style="margin-top: 40px"
          v-model="checktaskForm.intro"
          :options="editorOption"
        >
        </quill-editor>
      </el-form-item>
      <el-form-item label="检查清单">
        <el-select v-model="checktaskForm.checklist_id">
          <el-option
            v-for="item in checklists"
            :key="item._id.$id"
            :label="item.name"
            :value="item._id.$id"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div style="margin-bottom: 50px">
      <el-button type="primary" @click="submitForm">确认修改</el-button>
    </div>
  </div>
</template>

<script>
import {
  getCheckTask,
  updateCheckTask,
  getCheckLists,
} from "../../api/index.js";
import { getUserId } from "../../utils/store.js";
export default {
  data() {
    return {
      checktaskForm: {},
      checklists: [],
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },
  mounted() {
    this.fetchData();
    getCheckLists().then((res) => {
      if (res.data.code === 0) {
        this.checklists = res.data.data;
      }
    });
  },
  methods: {
    fetchData() {
      getCheckTask({
        id: this.$route.query.id,
      }).then((res) => {
        this.checktaskForm = res.data.data;
      });
    },
    submitForm() {
      updateCheckTask({
        id: this.$route.query.id,
        name: this.checktaskForm.name,
        intro: this.checktaskForm.intro,
        checklist_id: this.checktaskForm.checklist_id,
        user_id: getUserId(),
        time_limit: "",
      }).then((res) => {
        if (res.data.code === 0) {
          this.$message.success("修改成功");
          this.fetchData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.createrBook {
  width: 100%;
  padding: 16px;
}
</style>
<style>
.ql-container {
  height: 200px !important;
}
</style>
