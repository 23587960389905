import Cookies from "js-cookie";

export function setUserId(user_id) {
  Cookies.set("check_user_id", user_id);
}
export function getUserId() {
  return Cookies.get("check_user_id");
}

export function setUserType(type) {
  Cookies.set("check_user_type", type);
}
export function getUserType() {
  return Cookies.get("check_user_type");
}

export function setUserName(quality_user_name) {
  Cookies.set("check_user_name", quality_user_name);
}
export function getUserName() {
  return Cookies.get("check_user_name");
}

export function setUserEmail(user_email) {
  Cookies.set("check_user_email", user_email);
}

export function getUserEmail() {
  return Cookies.get("check_user_email");
}

export function setUserAvatar(avatar) {
  Cookies.set("check_user_avatar", avatar);
}

export function getUserAvatar() {
  return Cookies.get("check_user_avatar");
}

export function removeUserAvatar() {
  Cookies.remove("check_user_avatar");
}

export function removeUserId() {
  Cookies.remove("check_user_id");
}

export function removeUserName() {
  Cookies.remove("check_user_name");
}

export function removeUserEmail() {
  Cookies.remove("check_user_email");
}
