import Vue from "vue";
import ElementUI from "element-ui";
import App from "./App.vue";
import router from "./router";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css";

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "tailwindcss/tailwind.css";
import "./common.css";

import dayjs from "dayjs";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);
Vue.use(VueQuillEditor);
Vue.config.productionTip = false;
Vue.prototype.$dayjs = dayjs;

Vue.use(ElementUI);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
